*{
  margin: 0;
  padding: 0;
}

body {
  font-family: proxima-nova, sans-serif;
  background-color: #ffffff;
  color: #1c1e21;
  overflow-x: hidden;
}
a {
  text-decoration: none;
}
::-webkit-scrollbar {
  display: none;
}
html {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.justify-content-center {
  justify-content: center;
}
.align-items-center {
  align-items: center;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}
.Mui-error {
  font-size: 12px !important;
  letter-spacing: normal !important;
}
.MuiSelect-select {
  letter-spacing: normal !important;
}
.MuiMenuItem-root.MuiButtonBase-root {
  font-weight: normal !important;
}
/*****  L O G I N  *****/
.wrapper {
  background-image: url(../public/media/login/bg.svg);
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  background-size: cover;  
}
.colm-logo {
  flex: 0 0 50%;
  text-align: center;
}
.colm-form {
  flex: 0 0 40%;
  text-align: center;
  max-width: 430px;
}
.colm-logo h2 {
  font-size: 19px;
  font-weight: 600;
  margin: 55px 0 15px 0;
}
.colm-form .form-container {
  background-color: #ffffff;
  border: none;
  margin-bottom: 30px;
  padding: 20px;
  max-width: 500px;
}
.form-middle .btn-login {
  width: 100%;
  margin: 5px 0;
  height: 56px;
  vertical-align: middle;
}
.colm-form .form-container input, .colm-form .form-container {
  width: 100%;
  margin: 5px 0;
  height: 56px;
  vertical-align: middle;
}
.colm-form .form-container input {
  border: 1px solid #DDDDDD;
  border-radius: 4px;
  color: #444444;
  padding: 0 8px;
  outline: none;
  margin-bottom: 15px;
}
.colm-form .form-container input:focus {
  border-color: #1877f2;
  box-shadow: 0 0 0 2px #e7f3ff;
}
.btn-login {
  background-color: #0073E9;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  padding: 0 16px;
  color: #ffffff;
  width: 100%;
  height: 42px;
  cursor: pointer;
  position: relative;
  display: inline-block;
  transition: all .15s linear 0s;
  top: 0;
  right: 0;
  /* -webkit-mask: url(../public/media/login/urban-sprite.png);
  mask: url(../public/media/login/urban-sprite.png);
  -webkit-mask-size: 3000% 100%;
  mask-size: 3000% 100%;
  border: none;
  -webkit-animation: ani2 0.5s steps(29) forwards;
  animation: ani2 0.5s steps(29) forwards; */
}
.btn-login:hover {
  /* -webkit-animation: ani 0.5s steps(29) forwards;
  animation: ani 0.5s steps(29) forwards; */
  top: 3px;
    right: -3px;
    box-shadow: 4px 6px 0 #004a96;
    border-radius: 0;
}
.btn-login:hover::before {
  bottom: -4px;
  left: 1px;
  width: 6px;
  height: 6px;
  background-color: #004a96;
}
.btn-login::before {
  transition: all 0.15s linear 0s;
  content: "";
  position: absolute;
  bottom: -4px;
  right: 2px;
  width: 8px;
  height: 8px;
  transform: rotate(45deg);
  z-index: -1;
}
.btn-login:hover::after {
  top: 2px;
  right: -3px;
  width: 6px;
  height: 6px;
  background-color: #004a96;
}
.btn-login::after {
  transition: all 0.15s linear 0s;
  content: "";
  position: absolute;
  top: 2px;
  right: -4px;
  width: 8px;
  height: 8px;
  transform: rotate(47deg);
  z-index: -1;
}
.btn-login:active {
  top: 6px;
  right: -6px;
  box-shadow: none;
}
.btn-login:active:before {
  bottom: 1px;
  right: 1px;
}
.btn-login:active:after {
  top: 1px;
  left: 1px;
}

.btn-login-container {
  position: relative;
}
/* .btn-login-container .mas {
  position: absolute;
  color: #0073E9;
  width: 100%;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
}
@-webkit-keyframes ani {
  from {
   -webkit-mask-position: 0 0;
   mask-position: 0 0;
  }
 
  to {
   -webkit-mask-position: 100% 0;
   mask-position: 100% 0;
  }
 }
 
 @keyframes ani {
  from {
   -webkit-mask-position: 0 0;
   mask-position: 0 0;
  }
 
  to {
   -webkit-mask-position: 100% 0;
   mask-position: 100% 0;
  }
 } */

.colm-form .form-container a:hover {
  text-decoration: underline;
}

.colm-form .form-container .btn-new {
  background-color: #42b72a;
  border: none;
  border-radius: 6px;
  font-size: 17px;
  line-height: 48px;
  padding: 0 16px;
  color: #ffffff;
  font-weight: 700;
  margin-top: 20px;
}

.colm-form p {
  font-size: 14px;
}

.colm-form p a {
  text-decoration: none;
  color: #1c1e21;
  font-weight: 600;
}

.colm-form p a:hover {
  text-decoration: underline;
}
#mui-component-select-country_code {
  background-color: #ffffff;
}
/*****  F O O T E R  *****/
.footer-contents {
  width: 100%;
  font-size: 12px;
  text-align: center;
  padding: 2.5em 0 1.5em 0;
  color: #AAAAAA;
  margin-top: auto;
}

/*****  M A I N  P A G E  *****/
.header {
  justify-content: space-between;
  display:flex;
  align-items: center;
  padding: 2rem 4rem;
}
.container-fluid {
  padding: 0 58px;
  margin-top: auto;
}
.flex-container {
  display: grid;
  gap: 25px;
  grid-template-columns: repeat(auto-fill,minmax(310px,1fr));
}
.flex-item {
  padding: 3em 1.5em 2.5em 1.5em;
  /* width: 300px; */
  height: 330px;
  font-weight: bold;
  text-align: center;
  border: 1px solid #EAEAEA;
  border-radius: 8px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
}
.flex-item:hover {
  transition: transform 250ms;
  box-shadow: 6px 15px 50px 0px rgba(95,95,95,0.14);
  cursor: pointer;
}
.content {
    -webkit-flex-basis: 0;
    flex-basis: 0;
    -webkit-flex-grow: 1;
    flex-grow: 1;
    width: 100%;
}
.title-container {
  flex-grow: 1;
  margin-top: 20px;
}
.big-title {
  color: #34325b;
  font-size: 21px;
  font-weight:bold;
  text-align: left;
}
.small-title {
  color: #555555;
  font-size: 16px;
  text-align: left;
  font-weight: 200;
}

.round-button {
  width:15%;
}
.round-button-circle {
  width: 100%;
  height:0;
  padding-bottom: 100%;
  border-radius: 50%;
  overflow:hidden;
  background: #F4F8FE;
}

.img-container {
  display: flex;
  flex-direction: row;
  flex: 1 1;
}

.img-size {
  height: 178px;
}
.app-logo-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.logo-app img {
  vertical-align: middle;
  filter: grayscale(1);
  padding: 5px 9px;
  width: 15px;
}
.img-app {
  width: 100%;
}
.logo-app img:hover {
  filter: grayscale(0);
}
.logout {
  width: 38px;
  height: 38px;
  background-color: #EEEEEE;
  border: 0;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.logout:hover {
  background-color: #f4f4f4;
}
@media (max-width: 575px) {
  .container-fluid {
    padding: 0 15px;
  }
  .header {
    padding: 1rem;
    margin-bottom: 1rem;
  }
  .header img {
    width: 55%;
  }
}

.mx-2{margin-right:.5rem!important;margin-left:.5rem!important}